<template>
<div>
  <v-container>
    <v-row style="margin-top: 1%;">
      <v-col
          cols="12"
          md="6"
      >
        <v-select
            v-model="payment.fund_id"
            dense
            :items="fund_list"
            :item-value="'id'"
            :item-text="item=>item.fund_name+'('+item.fund_amount+' FCFA)'"
            label="Caisse concerner"
            outlined
        ></v-select>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="payment.pay_date"
            dense
            type="date"
            required
            hide-details
            label="Date de versement"
            outlined
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-select
            v-model="payment.type"
            dense
            :items="['entrant','sortant']"
            label="Type de Versement"
            outlined
        ></v-select>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="payment.pay_amount"
            dense
            type="numeric"
            hide-details
            required
            label="Montant versé"
            outlined
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="payment.pay_mode"
            dense
            hide-details
            label="Motif-mode de versement"
            outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="6"
      >
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-btn
            color="primary"
            @click="newFundTransaction"
        >
          Enregistrer
        </v-btn>

      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
export default {
  name: 'FundTransactionCreate',
  data() {
    return {
      payment: {
        pay_date: '',
        pay_amount: 0,
        pay_mode: '',
        type: '',
        fund_id: 0,
        user_id: JSON.parse(localStorage.getItem('currentUser')).id,
      },
      fund_list: [],
    }
  },
  mounted() {
    this.loadFund()
  },
  methods: {
    newFundTransaction() {
      // console.log(this.payment.type)
      this.$axios.post(this.$endpoint.CreatePayment, this.payment).then(rp => {
        this.$emit('success-fund')
        console.log(rp)
      })
    },
    loadFund() {
      this.$axios.get(this.$endpoint.LoadFund).then(rp => {
        this.fund_list = rp.data
      })
    },

  },
}
</script>

<style scoped>

</style>
