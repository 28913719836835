<template>
  <div>
    <v-row
      class="match-height"
      style="margin-bottom: 2%;"
    >
      <v-col
        cols="12"
        sm="12"
      >
<!--        <statistics-card-vertical-->
<!--          :color="totalProfit.color"-->
<!--          :icon="totalProfit.icon"-->
<!--          :statistics="fund_transaction_list.length"-->
<!--          :stat-title="totalProfit.statTitle"-->
<!--          :subtitle="totalProfit.subtitle"-->
<!--        ></statistics-card-vertical>-->
      </v-col>
    </v-row>
<!--    show-expand-->
<!--    :single-expand="singleExpand"-->
<!--    :expanded.sync="expanded"-->
    <v-data-table
      :headers="headers"
      :items="fund_transaction_list"
      item-key="full_name"
      class="table-rounded"
      :search="search"
>
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Liste des Versement</v-toolbar-title>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-row style="margin-left: 1.5rem; margin-right: 1.5rem;">
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
          </v-col>
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="createFundTransaction=true"
            >
              Nouveau Versement
            </v-btn>
          </v-col>
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="search"
              dense
              append-icon="mdi-magnify"
              label="Rechercher un versement"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template #expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="width: 100%;"
        >
          Details du Client:  <br>
          <p><span>Date Anniversaire</span> :<strong>{{ item.cust_birthday }}</strong></p>
          <p><span>Lieu de Naissance</span> :<strong>{{ item.cust_birth_place }}</strong></p>
          <p><span>Nom du pere</span> :<strong>{{ item.cust_father_name }}</strong></p>
          <p><span>Nom de la mere</span> :<strong>{{ item.cust_mother_name }}</strong></p>
          <p><span>Nom de caresse</span> :<strong>{{ item.cust_pseudonym }}</strong></p>
          <p><span>Pays</span> :<strong>{{ item.cust_country }}</strong></p>
          <p><span>Ville</span> :<strong>{{ item.cust_city }}</strong></p>
          <p><span>Date d'expiration de la piece</span> :<strong>{{ item.cust_id_expiration_date }}</strong></p>
          <p><span>Reference de la piece</span> :<strong>{{ item.cust_reference_id }}</strong></p>
          <p><span>Profession</span> :<strong>{{ item.cust_job }}</strong></p>
          <p><span>Domicile</span> :<strong>{{ item.cust_place_residence }}</strong></p>
          <p><span>Date d'entre en cote d'ivoire</span> :<strong>{{ item.cust_date_of_entering_ivory_coast }}</strong></p>
          <div style="display:flex; justify-content:space-between; align-items: start; padding: 1rem;">
            <v-btn
              color="primary"
              small
              @click="modify_customer(item)"
            >
              Modifier
            </v-btn>
          </div>
        </td>
      </template>
    </v-data-table>
    <!--    dialog to create client begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="createFundTransaction"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                color="primary"
                @click="createFundTransaction = false"
              >
                <mdi-close-circle />Annuler
              </v-btn>&emsp;
              <v-toolbar-title color="white">
                Enregistrer un nouveau Versement
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tab-item>
                <v-btn
                    color="primary"
                    @click="createFundTransaction = false"
                >
                  <mdi-close-circle />Fermer
                </v-btn>&emsp;
              </v-tab-item>
            </v-toolbar>
            <FundTransactionCreate @success-fund="closeFundCreate"></FundTransactionCreate>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import {mdiAccount, mdiDotsVertical, mdiSquareEditOutline} from '@mdi/js'
import FundTransactionCreate from '@/views/treasury/FundTransactionCreate'

export default {
  name: 'FundTransaction',
  components: { FundTransactionCreate },
  setup() {
    const totalProfit = {
      statTitle: 'Versement',
      icon: mdiAccount,
      color: 'success',
      subtitle: 'Versement enregistre',
    }
    return {
      headers: [
        { text: 'DATE DE PAIMENT', value: 'pay_date' },
        { text: 'MONTANT', value: 'pay_amount' },
        { text: 'CAISSE CONCERNE', value: 'fund.fund_name' },
        { text: 'MODE DE PAIEMENT', value: 'pay_mode' },
        { text: 'TYPE DE PAIEMENT', value: 'type' },

        // { text: 'APPARTEMENT CONCERNER', value: 'apart_concerning' },
        // { text: 'TYPE DE PAIEMENT', value: 'payment_types_id ' },
      ],
      totalProfit,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  data() {
    return {
      search: '',
      filtreTable: '',
      createFundTransaction: false,
      fund_transaction_list: [],
      fund_transaction_backup: [],
    }
  },
  mounted() {
    this.loadFundTransction()
  },
  methods: {
    filterFundTransaction() {
      this.fund_transaction_list = this.fund_transaction_list.filter(fdt => (fdt.apart_concerning === this.filtreTable)).length
      // eslint-disable-next-line radix
        ? this.fund_transaction_list.filter(fdt => (fdt.cust_city === this.filtreTable)) : this.fund_transaction_backup
    },
    closeFundCreate() {
      this.createFundTransaction = false
      this.loadFundTransction()

    },
    loadFundTransction() {
      this.$axios.get(this.$endpoint.LoadPayment).then(rp => {
        this.fund_transaction_list = rp.data
        this.fund_transaction_backup = rp.data
      })
    },
  },
}
</script>

<style scoped>

</style>
